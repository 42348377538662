<template>
    <b-card-actions ref="cardOperatorTable" title="Choferes Con Ruta (Histórico)" action-refresh @refresh="getStatisticRoutingByOperators">
        <vue-perfect-scrollbar ref="scrollContainerSearch" :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area">
            <div v-for="(employee, index) in employeeData" :key="index"
                class="employee-task_ d-flex justify-content-between align-items-center">
                <b-media no-body>
                    <b-media-aside class="mr-75">
                        <b-avatar rounded-circle size="42" :src="null" :text="employee.totalRutas.toString()" 
                        :style="{ backgroundColor: employee.avatarColor }" />
                    </b-media-aside>
                    <b-media-body class="my-auto">
                        <h6 class="mb-0">
                        <span class="text-wrapp">{{ employee.chofer }}</span> 
                        </h6>
                        <small>   
                            <feather-icon icon="CalendarIcon"/> Desde {{ formatDate(employee.primeraAsignacion) }} <br>
                            <feather-icon icon="CalendarIcon"/> Hasta {{ formatDate(employee.ultimaAsignacion) }}
                        </small>
                    </b-media-body>
                </b-media>
                <div class="d-flex align-items-center">
                    <small class="text-muted mr-75">
                        {{ parseFloat(employee.porcentaje).toFixed(2) }}%
                    </small>

                    <!-- chart -->
                    <vue-apex-charts v-if="employeeData" type="radialBar" height="30" width="30" :options="chartData[index].options"
                        :series="chartData[index].series" />
                </div>
            </div>
        </vue-perfect-scrollbar>
    </b-card-actions>
</template>

<script>
import {
    BCard, BCardTitle, BCardHeader, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge, BOverlay,
    BButton,
} from 'bootstrap-vue'
import store from '@/store'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'

import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
    components: {
        BCard,
        BCardTitle,
        BCardHeader,
        BCardBody,
        BMedia,
        BMediaAside,
        BMediaBody,
        BAvatar,
        VueApexCharts,
        BBadge,
        BOverlay,
        BButton,

        //
        BCardActions,
        VuePerfectScrollbar,
    },
    data() {
        return {
            loading: false,
            perfectScrollbarSettings: {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            },
            chartData: [],
            chartColor: [$themeColors.primary, $themeColors.danger, $themeColors.success, $themeColors.secondary, $themeColors.warning, $themeColors.primary],
            chartSeries: [45, 65, 60, 35, 65, 80],
            employeeData: [],
            chart: {
                series: [65],
                options: {
                    grid: {
                        show: false,
                        padding: {
                            left: -15,
                            right: -15,
                            top: -12,
                            bottom: -15,
                        },
                    },
                    colors: [$themeColors.primary],
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                size: '22%',
                            },
                            track: {
                                background: $trackBgColor,
                            },
                            dataLabels: {
                                showOn: 'always',
                                name: {
                                    show: false,
                                },
                                value: {
                                    show: false,
                                },
                            },
                        },
                    },
                    stroke: {
                        lineCap: 'round',
                    },
                },
            },

        }
    },
    computed: {
        isReaload() {
            return store.getters['routing/getIsRealodAbierta'] || store.getters['routing/getIsRealodEnRuta'] ||
                store.getters['routing/getIsRealoadCerrada'] || store.getters['routing/getIsRealodClientePasa']
        }
    },
    created() {
      
    },
    async mounted() {
        await this.getStatisticRoutingByOperators()
    },
    methods: {
        getRandomColor() {
            const colors = [$themeColors.primary, $themeColors.danger, $themeColors.success, $themeColors.secondary, $themeColors.warning, $themeColors.primary];
            return colors[Math.floor(Math.random() * colors.length)];
        },
        async getStatisticRoutingByOperators() {

            // Esperar a que Vue actualice el DOM
            await this.$nextTick()

            this.$refs['cardOperatorTable'].showLoading = true

            try {
                
                const { data } = await DashboardsResource.getStatisticRoutingByOperators()
                if (data.isSuccesful) {
                    this.employeeData = data.data.map(e => {
                        return {
                           ...e,
                           avatarColor: this.getRandomColor(), // Asignar color aleatorio al avatar
                        }
                    })
                    this.chartData = this.employeeData.map((chofer) => {
                        return {
                            series: [chofer.porcentaje],
                            options: {
                            grid: {
                                show: false,
                                padding: {
                                    left: -15,
                                    right: -15,
                                    top: -12,
                                    bottom: -15,
                                },
                            },
                            colors: [this.getRandomColor()],
                            plotOptions: {
                                radialBar: {
                                    hollow: {
                                        size: '22%',
                                    },
                                    track: {
                                        background: '#e7e7e7',
                                    },
                                    dataLabels: {
                                        showOn: 'always',
                                        name: {
                                            show: false,
                                            formatter: () => chofer.total_Rutas, // Mostrar el nombre del chofer
                                        },
                                        value: {
                                            show: false,
                                            formatter: (val) => `${val.toFixed(2)}%`, // Mostrar el porcentaje
                                        },
                                    },
                                },
                            },
                            stroke: {
                                lineCap: 'round',
                            },
                            },
                        };                        
                    });
                }
            } catch (error) {
                this.$refs['cardOperatorTable'].showLoading = false
                console.log(error)
            }finally {
                this.$refs['cardOperatorTable'].showLoading = false
            }

        },
    },
}
/* eslint-disable global-require */
</script>