<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-card no-body>
            <b-card-header>
                <b-card-title>Ventas Con Descuentos</b-card-title>
                <b-row class="mt-1">
                    <b-col cols="12" md="3">
                        <b-form-group label="Fecha Inicio" label-for="date_start">
                            <flat-pickr v-model="filter.fechaIni" id="date_start" class="form-control flatpickr-small"
                                :config="{ dateFormat: 'Y-m-d' }" placeholder="DD/MM/YYYY" />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="3">
                        <b-form-group label="Fecha Fin" label-for="date_start">
                            <flat-pickr v-model="filter.fechaFin" id="date_end" class="form-control flatpickr-small"
                                :config="{ dateFormat: 'Y-m-d' }" placeholder="DD/MM/YYYY" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-form-group label="Comparar Con" label-for="date_start">
                            <v-select id="precio_Empresa_Id"
                                class="select-size-sm"
                                v-model="filter.precioListaId"
                                :options="pricesList"
                                :reduce="option => option.precio_Empresa_Id" 
                                label="nombre" placeholder="Selecciona una opción"
                                :clearable="false" 
                                :searchable="false"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="3" class="mt-2">
                        <b-button :disabled="loading" @click="getSalesDisccounts" size="sm" variant="primary" class="mr-1">
                            <feather-icon icon="SearchIcon" /> Buscar
                        </b-button>

                        <b-button :disabled="loading" @click="downloadDisccounts" title="Descargar" size="sm" variant="outline-primary" class="mr-1_ btn-icon">
                            <feather-icon icon="DownloadIcon" />
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-header> 

            <!-- Master Table -->
            <div ref="tableContainer" class="table-responsive" :class="`b-table-sticky-header`">
                <table class="table b-table table-sm b-table-no-border-collapse  b-table-selectable b-table-select-single w-100 text-small small">
                <thead>
                    <tr>
                        <th v-for="(column, index) in columns" :key="index" @click="sortColumnAsync(column)"
                            style="font-size: 0.6rem!important; cursor: pointer;">
                            {{ column.label }}
                            <span v-if="sorting.column === column.key">
                                <feather-icon v-if="sorting.direction === 'desc'" icon="ChevronDownIcon" />
                                <feather-icon v-if="sorting.direction === 'asc'" icon="ChevronUpIcon" />
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody v-for="(item, index) in groupedData" :key="index">
                    <tr>
                        <th style="font-size: 9px;">{{ customDate(item.fecha, 'DD/MM/YYYY') }}</th>
                        <th style="font-size: 9px;">{{ item.folio }}</th>
                        <th style="font-size: 9px;">{{ item.cliente }}</th>                        
                        <th style="font-size: 9px;">{{ item.vendedor }}</th>
                        <th style="font-size: 9px;">{{ item.forma_Cobro }}</th>
                    </tr>
                    <tr>
                        <td colspan="5">
                            <table class="table table-bordered small text-small table-sm">
                                <thead>
                                    <tr>
                                        <th style="font-size: 8px;">Articulo</th>
                                        <th style="font-size: 8px;">Clave</th>
                                        <th style="font-size: 8px;">Um Venta</th>
                                        <th style="font-size: 8px;">Unidades</th>
                                        <th style="font-size: 8px;">Precio Venta</th>
                                        <!-- <th style="font-size: 8px;">Precio Lista</th> -->
                                        <th style="font-size: 8px;">Desc</th>
                                        <th style="font-size: 8px;">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(detail, detailIndex) in item.details" :key="'detail-' + detailIndex">
                                        <td style="font-size: 9px;">{{ detail.articulo }}</td>
                                        <td style="font-size: 9px;">{{ detail.clave_Articulo }}</td>
                                        <td style="font-size: 9px;" class="text-center">{{ detail.unidad_Venta }}</td>
                                        <td style="font-size: 9px;" class="text-center">{{ detail.unidades }}</td>
                                        <!-- <td style="font-size: 9px;">{{ formatCurrency(detail.precio_Total_Neto) }}</td> -->
                                        <td style="font-size: 9px;">
                                            {{ formatCurrency(detail.precio_Unitario, 4) }}
                                        </td>
                                        <!-- <td style="font-size: 9px;">{{ formatCurrency(detail.precio_Lista) }}</td> -->
                                        <td style="font-size: 9px;" :class="getDiscountClass(detail.descuento_Calculado)"
                                            :style="getTextStyle(detail.descuento_Calculado)"
                                            v-b-tooltip.hover="{ title: getTooltip(detail.descuento_Calculado) }">{{ numberFormat(detail.descuento_Calculado, 2) }}%</td>
                                        <td style="font-size: 9px;">{{ formatCurrency(detail.unidades * detail.precio_Unitario) }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th style="font-size: 10px;"></th>
                                        <th style="font-size: 10px;">Subtotal</th>
                                        <th style="font-size: 10px;">{{ formatCurrency(item.details[0].importe_Neto) }}</th>                                        
                                        <th style="font-size: 10px;">Impuesto</th>
                                        <th style="font-size: 10px;">{{ formatCurrency(item.details[0].total_Impuestos) }}</th>
                                        <th style="font-size: 10px;" class="text-right">Total</th>
                                        <th style="font-size: 10px;">{{ formatCurrency(item.details[0].importe_Neto + item.details[0].total_Impuestos) }}</th>
                                    </tr>
                                    <tr>
                                        <th colspan="6" style="font-size: 10px;" class="text-right">Total Real</th>
                                        <th style="font-size: 10px;">{{ formatCurrency(totalReal(item.details)) }}</th>
                                    </tr>
                                    <tr>
                                        <th colspan="6" style="font-size: 10px;" class="text-right">Promedio Desc</th>
                                        <th style="font-size: 10px;">
                                            {{ numberFormat(promedioDescuentoPonderado(item.details), 3)}}
                                        </th>
                                    </tr>
                                </tfoot>
                            </table>
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
import {
	BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
	BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BFormRadio, BFormRadioGroup, BFormTextarea, BFormInvalidFeedback,
	BButton, BOverlay, BTable, BTooltip, VBTooltip,
} from 'bootstrap-vue'

import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import * as helper from '@/libs/helpers';
import vSelect from "vue-select";
import { heightTransition } from '@core/mixins/ui/transition'
import moment from 'moment';
import store from '@/store'
import { computed, nextTick, onMounted, ref } from "@vue/composition-api";
import Ripple from 'vue-ripple-directive'

import ExportsProvider from '@/providers/Exports'
const ExportsResource = new ExportsProvider()
import PricesArticlesProvider from '@/providers/PricesArticles'
const PricesArticlesResource = new PricesArticlesProvider()

import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
    name: 'SalesDisccounts',
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardTitle,
		BCardText,
		BCardBody,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,
		BFormGroup, 
		BFormInput, 
		BFormSelect, 
		BFormCheckbox, 
		BFormRadio, 
		BFormRadioGroup, 
		BFormTextarea, 
		BFormInvalidFeedback,
		BButton,
        BOverlay,
        BTable,
        BTooltip, 
        VBTooltip,

		//
		flatPickr,
        vSelect,
	},
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
	mixins: [heightTransition],
    methods: {
        totalReal(items) {
            return items.reduce((carry, d) => {
                return carry + (d.unidades * d.precio_Lista);
            }, 0)
        },
        promedioDescuentoPonderado(items) {
            return items.reduce((total, item) => total + (item.descuento_Calculado * item.unidades), 0) / items.reduce((carry, d) => carry + d.unidades, 0);
        },
        totalDescuentoPonderado(items) {
            return items.reduce((total, item) => total + (item.descuento_Calculado * item.unidades), 0);
        },
        totalUnidades (items) {
            return items.reduce((total, item) => total + item.unidades, 0);
        },
        async sortColumnAsync(column) {
            
            // Mostrar el spinner de carga
            this.loading = true;

            // Esperar a que la UI actualice el estado del spinner
            await this.$nextTick();
            
            if (this.sorting.column === column.key) {
                this.sorting.direction = this.sorting.direction === 'asc' ? 'desc' : 'asc';
            } else {
                this.sorting.column = column.key;
                this.sorting.direction = 'asc';
            }            
            
            // Simular un retraso para la carga (puedes eliminar esto en un caso real)
            await new Promise(resolve => setTimeout(resolve, 500));

            this.loading = false
        },        
        toggleDetails(index) {
            // this.groupedData[index].showDetails = !this.groupedData[index].showDetails;
            console.log(this.groupedData[index])
        }
    },
    setup() {

        const sortOrder = ref('asc')
        const sortColumn = ref('')
        const loading = ref(false)
        const articles = ref([])
        const pricesList = ref([])
		const filter = ref({
			fechaIni: moment().startOf('month').format('YYYY-MM-DD'),
			fechaFin: moment().format('YYYY-MM-DD'),
			tipoBitacora: null,
            precioListaId: null,
		})
        const columns = ref([
            { key: 'fecha', label: 'Fecha', sortable: true, },
            { key: 'folio', label: 'Folio', sortable: true, },
            { key: 'cliente', label: 'Cliente', sortable: true, },
            { key: 'vendedor', label: 'Vendedor', sortable: true, },
            { key: 'forma_Cobro', label: 'Forma Cobro', sortable: true, },
        ])
        const sorting = ref({
            column: null,
            direction: null // 'asc' or 'desc'
        })

        const groupedData = computed(() => {

            const sortedData = [...articles.value]; // Create a copy to avoid mutating original data
            if (sorting.value.column) {
                sortedData.sort((a, b) => {
                    const aValue = a[sorting.value.column];
                    const bValue = b[sorting.value.column];
                    if (typeof aValue === 'string') {
                        return aValue.localeCompare(bValue, undefined, { sensitivity: 'base' });
                    } else {
                        return aValue - bValue;
                    }
                });

                if (sorting.value.direction === 'desc') {
                    sortedData.reverse();
                }
            }

            // Agrupación por cliente y folio
            const grouped = [];
            sortedData.forEach(item => {
                const existingGroup = grouped.find(
                    group => group.cliente === item.cliente && group.folio === item.folio
                );

                if (!existingGroup) {
                    grouped.push({
                        cliente: item.cliente,
                        docto_Pv_Id: item.docto_Pv_Id,
                        folio: item.folio,
                        fecha: item.fecha,
                        vendedor: item.vendedor,
                        forma_Cobro: item.forma_Cobro,
                        details: [item],
                        showDetails: true
                    });
                } else {
                    existingGroup.details.push(item);
                }
            });
            return grouped;
        })

        const getSalesDisccounts = async () => {
            loading.value = true
            articles.value = []
            sorting.value = {
                column: null,
                direction: null
            }

            try {
                const { data } = await DashboardsResource.getDoctosPvDescuentos(filter.value)
                loading.value = false
                if (data.isSuccesful) {
                    articles.value = data.data
                } else {
                    helper.danger(data.message)
                }
            } catch (error) {
                loading.value = false
                helper.handleResponseErrors(error)
            }
        }

        const getPricesList = async () => {
            const idEmpresa = store.getters['auth/getConexionId']
            try {
                loading.value = true
                const { data } = await PricesArticlesResource.getAllPricesList(idEmpresa)
                loading.value = false                
                pricesList.value = data.data

                nextTick(() => {
                    filter.value.precioListaId = pricesList.value[0].precio_Empresa_Id
                })

            }catch(error) {
                loading.value = false
                console.error("Error loading prices list", error)
                helper.handleResponseErrors(error)
            }
        }

        const downloadDisccounts = () => {
            loading.value = true;

            const fechaIniFormatted = moment(filter.value.fechaIni).format('DD-MMMM-YYYY');
            const fechaFinFormatted = moment(filter.value.fechaFin).format('DD-MMMM-YYYY');
            const fileName = `Descuentos desde ${fechaIniFormatted} hasta ${fechaFinFormatted}.xlsx`;

            ExportsResource.ExportDoctosPvDescuentos(filter.value).then(({ data }) => {
                // Crear un enlace para la descarga
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName); // Establecer el nombre de archivo
                document.body.appendChild(link);
                link.click();
                link.remove(); // Eliminar el enlace una vez que no es necesario
                loading.value = false;
            }).catch(err => {
                loading.value = false;
                console.error("Error al descargar el archivo:", err);
            });   
        }

        const getDiscountClass = (discount) => {
            if (discount < 0) {
                return 'bg-green-light'; // Verde claro.
            } else if (discount <= 5) {
                return 'bg-yellow-light'; // Amarillo claro.
            } else if (discount <= 10) {
                return 'bg-orange-light'; // Naranja claro.
            } else if (discount <= 15) {
                return 'bg-red-light'; // Rojo claro.
            } else if (discount <= 20) {
                return 'bg-red-medium'; // Rojo medio.
            } else if (discount <= 50) {
                return 'bg-red-dark'; // Rojo oscuro.
            } else {
                return 'bg-red-intense'; // Rojo intenso.
            }
        }

        const getTextStyle = (discount) => {
            if (discount >= 20 ) {
                return { color: 'white' };
            }
            return { color: 'black' };
        }

        const getTooltip = (discount) => {
            if (discount < 0) {
                return '';
            } else if (discount <= 5) {
                return 'Descuento del (0-5%)';
            } else if (discount <= 10) {
                return 'Descuento del (5-10%)';
            } else if (discount <= 15) {
                return 'Descuento del (10-15%)';
            } else if (discount <= 20) {
                return 'Descuento del (15-20%)';
            } else if (discount <= 50) {
                return 'Descuento del (20-50%)';
            } else {
                return 'Descuento del (50-100%)';
            }
        }


        onMounted(async () => {
            await getPricesList()
        })
            
        return {
            loading,
            filter,
            articles,
            pricesList,
            sortOrder,
            sortColumn,
            columns,
            sorting,

            //
            groupedData,

            getDiscountClass,
            getTextStyle,
            getTooltip,
            downloadDisccounts,
            getSalesDisccounts,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

</style>

<style>
/* .b-table-sticky-header {
    overflow-y: auto;
    max-height: 300px;
} */

/* Colores para descuentos */
.bg-green-light {
  background-color: #d4f9d4;
}
.bg-yellow-light {
  background-color: #fff9d4;
}
.bg-orange-light {
  background-color: #ffe5cc;
}
.bg-red-light {
  background-color: #ffb3b3; /* Rojo claro */
}
.bg-red-medium {
  background-color: #ff6666; /* Rojo medio */
}
.bg-red-dark {
  background-color: #cc0000; /* Rojo oscuro */
}
.bg-red-intense {
  background-color: #990000; /* Rojo intenso */
}

/* Estilo adicional para tooltips */
.tooltip-inner {
  background-color: #333;
  color: white;
}
</style>