import axios from '@/libs/axios'

export default class Reports {

    //Obtener los articulos con sus importes y lineas en las rutas entregas detalles
    // GET /v2/Reportes/GetArticulosDoctosDet    
    getArticulosDoctosDet (query) {
        return axios.get(`/v2/Reportes/GetArticulosDoctosDet`, { params: query})
    }

    //Obtener el estado de cuenta del cliente en PDF
    // GET /v2/Reportes/GetStatementAccountPDF
    getStatementAccountPDF (query) {
        return axios.get(`/v2/Reportes/GetStatementAccountPDF`, {
            responseType: 'blob',
            params: query
        })
    }

    getArticlesToBeDelivered (query) {
        return axios.get(`/v2/Reportes/GetArticlesDelivered`, { params: query})
    }
}