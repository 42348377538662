<template>
    <div>
        <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
            <b-row>
                <b-col cols="12">
                    <b-card no-body title="Con fecha de timbrado distinta de la fecha de venta" class="invoice-actions">
                        <b-card-header>
                            <b-card-title>{{ title }}</b-card-title>
                        </b-card-header>
                        <b-card-body>
                            <b-row>
                                <b-col cols="12" md="3">
                                    <b-form-group label="Fecha Inicio" label-for="date_start">
                                        <flat-pickr v-model="filters.fechaIni" id="date_start"
                                            class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                            placeholder="DD/MM/YYYY" />
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="3">
                                    <b-form-group label="Fecha Fin" label-for="date_start">
                                        <flat-pickr v-model="filters.fechaFin" id="date_end"
                                            class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                            placeholder="DD/MM/YYYY" />
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="4" class="mt-2">
                                    <b-button :disabled="loading" @click="getDocuments" size="sm"
                                        variant="primary" class="mr-1">
                                        <feather-icon icon="SearchIcon" /> Buscar
                                    </b-button>

                                    <!-- <b-button size="sm" v-if="documents.length > 0" @click="exportToExcel" variant="secondary"
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                                        <feather-icon icon="PrinterIcon" />
                                        Imprimir
                                    </b-button> -->
                                </b-col>
                            </b-row>
                        </b-card-body>

                        <div class="position-relative b-table-responsive mb-0 text-small small table-responsive"
                            :class="`${documents.length > 10 ? 'b-table-sticky-header' : ''}`">
                                <table ref="refTable" class="table b-table table-striped table-sm b-table-no-border-collapse text-small small b-table-small b-table-selectable b-table-select-single">                                    
                                    <thead>
                                        <tr>
                                            <th>Folio Factura</th>
                                            <th>Fecha Factura</th>
                                            <th>Folio Venta</th>
                                            <th>Fecha Venta</th>
                                        </tr>                                        
                                    </thead>
                                    <tbody v-for="(doc, index) in documents" :key="index">
                                        <tr>
                                            <td><strong>{{ doc.folio_Factura }}</strong></td>
                                            <td class="text-capitalize"><strong>{{ formatDate(doc.fecha_Factura) }}</strong></td>
                                            <td><strong>{{ doc.folio_Venta }}</strong></td>
                                            <td class="text-capitalize"><strong>{{ formatDate(doc.fecha_Venta) }}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <table class="table table-bordered b-table table-sm b-table-no-border-collapse text-small small b-table-small b-table-selectable b-table-select-single">
                                                    <thead>
                                                        <tr>
                                                            <th style="font-size: 9px!important;" colspan="4" class="text-center">Notas de Crédito</th>
                                                        </tr>
                                                        <tr>
                                                            <th></th>
                                                            <th style="font-size: 9px!important;">Folio</th>
                                                            <th style="font-size: 9px!important;">Fecha</th>
                                                            <th style="font-size: 9px!important;">Importe</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </td>
                                            <td colspan="2">
                                                <table class="table table-bordered b-table table-sm b-table-no-border-collapse text-small small b-table-small b-table-selectable b-table-select-single">
                                                    <thead>
                                                        <tr>
                                                            <th style="font-size: 9px!important;" colspan="4" class="text-center">Pagos</th>
                                                        </tr>
                                                        <tr>                                                            
                                                            <th colspan="2" style="font-size: 9px!important;">Forma Cobro</th>
                                                            <th style="font-size: 9px!important;">Tipo</th>
                                                            <th style="font-size: 9px!important;">Importe</th>
                                                        </tr>
                                                    </thead> 
                                                    <tbody>
                                                        <tr v-for="(cobro, indexCobro) in doc.doctosPvCobros" :key="index+indexCobro">
                                                            <td colspan="2" style="font-size: 9px!important;">{{ cobro.formas_Cobro }}</td>
                                                            <td style="font-size: 9px!important;">{{ cobro.tipo }}</td>
                                                            <td style="font-size: 9px!important;">{{ formatCurrency(cobro.importe) }}</td>
                                                        </tr>
                                                    </tbody>                                                   
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                    </b-card>
                </b-col>
            </b-row>
        </b-overlay>
    </div>
</template>

<script>

import {
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BCardTitle,
    BTable,
    BTableLite,
    BOverlay,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
    BFormSelect,
    BBadge,
    BFormRadioGroup,
    BPagination,
    BTooltip,
    VBTooltip,

} from 'bootstrap-vue'
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import store from '@/store'
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import * as helper from '@/libs/helpers';
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex';
import moment from 'moment';
import * as XLSX from 'xlsx';

import DoctosPVProvider from '@/providers/DoctosPV';
const DoctosPVResource = new DoctosPVProvider();

export default {
    name: "StampedDocuments",
    components: {
        BCard,
        BCardBody,
        BCardHeader,
        BCardTitle,
        BCardText,
        BTable,
        BTableLite,
        BOverlay,
        BSpinner,
        BRow,
        BCol,
        BButton,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BFormGroup,
        BFormSelect,
        BBadge,
        BFormRadioGroup,
        BPagination,
        BTooltip,
        VBTooltip,

        //
        flatPickr,
        vSelect,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    props: {
        title: {
            type: String,
            default: 'Documentos Timbrados con fecha de factura distinta de la fecha de venta'
        }
    },
    setup() {
        const loading = ref(false)
        const documents = ref([])
        const refTable = ref(null)

        const filters = reactive({
            fechaIni: moment().startOf('month').format('YYYY-MM-DD'),
            fechaFin: moment().format('YYYY-MM-DD'),
        })

        const getDocuments = async () => {
            documents.value = []
            loading.value = true
            try {
                const { data } = await DoctosPVResource.getDocumentosTimbrados(filters)
                if (data.isSuccesful) {
                    documents.value = data.data
                } else {
                    helper.danger(data.message)
                }
            } catch (error) {
                helper.handleResponseErrors(error)
            } finally {
                loading.value = false
            }            
        }

        onMounted(async () => {
            await getDocuments()
        })

        const exportToExcel = () => {
            const table = refTable.value;
            const wb = XLSX.utils.table_to_book(table);
            XLSX.writeFile(wb, 'Documentos-Timbrados.xlsx');
        }

        return {
            loading,
            documents,
            filters,
            refTable,

            //
            getDocuments,
            exportToExcel,
        }
        
    },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>


<style>
.b-table-sticky-header {
    overflow-y: auto;
    /* max-height: 480px; */
    max-height: calc(100vh - 130px)!important;
}
</style>