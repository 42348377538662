<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-card no-body>
            <b-card-header>
                <b-card-title>Cobranza de Clientes</b-card-title>
                    <!-- <b-form-group label="Consultar a partir de" label-cols-sm label-for="date_start">
                        <flat-pickr v-model="filters.fechaIni" id="date_start" class="form-control flatpickr-small"
                        :config="{ dateFormat: 'Y-m-d' }" placeholder="DD/MM/YYYY" />                
                    </b-form-group>-->
            </b-card-header>

            <b-card-body v-show="charges">

                <vue-apex-charts
                 v-if="amounts.length > 0"
                    type="pie"
                    ref="donutChart"
                    height="300"
                    :options="chartOptions"
                    :series="amounts"
                    @dataPointSelection="handleDataPointSelection" 
                />

                <!-- apex chart -->
                <!-- <vue-apex-charts
                    type="pie"
                    ref="donutChart"
                    height="300"
                    :options="customersPie.chartOptions"
                    :series="customersPie.series"
                /> -->

                <!-- list group -->
                <!-- <div class="pt-25">
                    <div v-for="(data, index) in chartData.listData" :key="index" class="d-flex justify-content-between"
                        :class="index === Object.keys(chartData.listData).length - 1 ? '' : 'mb-1'">
                        <div class="series-info">
                            <feather-icon :icon="data.icon" size="16" class="mr-50" :class="data.iconColor" />
                            <span class="font-weight-bolder">{{ data.text }}</span>
                        </div>
                        <span><strong>{{ data.result }}</strong></span>
                    </div>                
                </div> -->
            </b-card-body>
        </b-card>
    </b-overlay>
</template>

<script>
import moment from 'moment'
import lodash from 'lodash'
import {
    BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody,
    BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BFormRadio, BFormRadioGroup, 
    BFormTextarea, BFormInvalidFeedback, BButton, BOverlay,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { computed, nextTick, onMounted, ref } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { kFormatter } from '@core/utils/filter'
import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
    name: 'CustomerChargesDotnut',
    components: {
        BCard,
        BCardHeader,
        BCardTitle,
        BDropdown,
        BDropdownItem,
        BCardBody,
        BFormGroup, 
        BFormInput, 
        BFormSelect, 
        BFormCheckbox, 
        BFormRadio, 
        BFormRadioGroup, 
        BFormTextarea, 
        BFormInvalidFeedback, 
        BButton,
        BOverlay,
        VueApexCharts,

        //
        flatPickr,
    },
    data() {
        return {
            loading: false,
            filters: {
                fechaIni: moment().add(-1, 'year').startOf('year').format('YYYY-MM-DD'),
                fechaFin: moment().format('YYYY-MM-DD'),
            },
            chartData: {},
            selectedPoint: null, // Almacena índices seleccionados           
            customersPie: {
                series: [],
                chartOptions: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                    },
                    labels: [],
                    dataLabels: {
                        enabled: true,
                        fontSize: '6px',
                        fontFamily: 'Montserrat',
                        formatter(val) {
                            if (val) {
                                return `${parseFloat(val).toFixed(2)}%`
                            }
                        },
                    },
                    tooltip: {
                        enabled: true, // Habilita el tooltip                        
                        y: {
                            formatter(val) {
                                if (val) {
                                    return kFormatter(val);
                                }
                            }
                        }
                    },
                    legend: { show: false },
                    stroke: {
                        width: 6,
                    },
                    colors: [
                        $themeColors.warning, // Por vencer
                        "#FFB3B3",            // Vencidas de 1 a 30 días (rojo claro)
                        $themeColors.danger,  // Vencidas de 31 a 60 días (rojo fuerte)
                        "#C70039",            // Vencidas de 61 a 90 días (rojo más oscuro)
                        $themeColors.dark,    // Vencidas de 91 días a un año (oscuro)
                        "#4E4E4E"             // Vencidas de un año en adelante (gris muy oscuro)
                    ],
                },
            },
            charges: [],  
            categories: [
                "Por vencer",
                "Vencidas de 1 a 30 días",
                "Vencidas de 31 a 60 días",
                "Vencidas de 61 a 90 días",
                "Vencidas de 91 días a un año",
                "Vencidas de un año en adelante"
            ],
            amounts: [], // Importes totales por categoría
            chartOptions: {
                chart: {
                    type: "pie",
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            // Puedes manejar el evento aquí también.
                            console.log("Punto seleccionado:", config);
                        },
                    },
                },
                labels: [], // Se configurará dinámicamente
                tooltip: {
                    enabled: true, // Habilita el tooltip
                    y: {
                        formatter(val) {
                            if (val) {
                                return kFormatter(val);
                            }
                        }
                    }
                },
                colors: [
                    $themeColors.warning, // Por vencer
                    "#FFB3B3",            // Vencidas de 1 a 30 días (rojo claro)
                    $themeColors.danger,  // Vencidas de 31 a 60 días (rojo fuerte)
                    "#C70039",            // Vencidas de 61 a 90 días (rojo más oscuro)
                    $themeColors.dark,    // Vencidas de 91 días a un año (oscuro)
                    "#4E4E4E"             // Vencidas de un año en adelante (gris muy oscuro)
                ],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 300
                            },
                            legend: {
                                osition: "bottom"
                            }
                        }
                    }
                ]
            }
        }
    },
    created() {
        // this.chartOptions.labels = this.categories;
    },
    async mounted() {
        await this.getCustomerCharges()
    },
    methods: {
        kFormatter,
        mapColorToClass(hexColor) {
            const colorMap = {
                [$themeColors.primary]: 'primary',
                [$themeColors.warning]: 'warning',
                [$themeColors.danger]: 'danger',
                [$themeColors.success]: 'success',
                [$themeColors.info]: 'info',
                [$themeColors.secondary]: 'secondary',
            };
            return colorMap[hexColor] || 'muted'; // Devuelve un color predeterminado si no se encuentra.
        },
        handleDataPointSelection(event, chartContext, config) {

            const { dataPointIndex } = config; // Índice del punto seleccionado

            if (this.selectedPoint === dataPointIndex) {
                // Si el mismo punto está seleccionado, lo deseleccionamos
                this.selectedPoint = null;
            } else {
                // Seleccionamos un nuevo punto
                this.selectedPoint = dataPointIndex;
            }

            // El índice seleccionado está en config.dataPointIndex
            const categoryIndex = dataPointIndex;
            const category = this.chartOptions.labels[categoryIndex];
            const value = this.amounts[categoryIndex];

            // Aquí puedes abrir un modal, navegar a otra página, o mostrar más detalles
            this.$emit('dataPointSelected', category, value, this.selectedPoint);

        },
        async getCustomerCharges() {
            this.charges = []
            this.loading = true

            try {
                const { data } = await DashboardsResource.getCustomerCharges(this.filters)
                if (data.isSuccesful) {
                    if (data.data.length == 0) {
                        this.danger('No se encontraron facturas')
                    } else {
                        this.charges = data.data

                        // Inicializar las categorías.
                        const categories = {
                            "Por vencer": 0,
                            "Vencidas de 1 a 30 días": 0,
                            "Vencidas de 31 a 60 días": 0,
                            "Vencidas de 61 a 90 días": 0,
                            "Vencidas de 91 días a un año": 0,
                            "Vencidas de un año en adelante": 0,
                        };

                        this.charges.forEach((charge) => {
                            const atraso = charge.atraso;
                            const saldo = charge.saldo_Cargo;
                            if (atraso <= 0) {
                                categories["Por vencer"] += saldo;
                            } else if (atraso >= 1 && atraso <= 30) {
                                categories["Vencidas de 1 a 30 días"] += saldo;
                            } else if (atraso >= 31 && atraso <= 60) {
                                categories["Vencidas de 31 a 60 días"] += saldo;
                            } else if (atraso >= 61 && atraso <= 90) {
                                categories["Vencidas de 61 a 90 días"] += saldo;
                            } else if (atraso >= 91 && atraso <= 365) {
                                categories["Vencidas de 91 días a un año"] += saldo;
                            } else {
                                categories["Vencidas de un año en adelante"] += saldo;
                            }
                        });

                        // Actualizar las series y labels.
                        this.chartOptions.labels = this.categories;
                        this.amounts = Object.values(categories);
                        
                        // this.customersPie.series = Object.values(categories);
                        // this.customersPie.labels = Object.keys(categories);
                        
                        // this.chartData.listData = Object.keys(categories).map((key, index) => {
                        //     return {
                        //         icon: 'CircleIcon',
                        //         iconColor: `text-${this.mapColorToClass(this.customersPie.chartOptions.colors[index])}`,
                        //         text: key,
                        //         result: this.formatCurrency(categories[key]),
                        //     };
                        // });

                        // this.$nextTick(() => {
                        //     this.$refs.donutChart.updateSeries(this.customersPie.series, true);
                        //     this.$refs.donutChart.updateOptions(this.customersPie.chartOptions, true, true, true);
                        // });
                    }
                } else {
                    this.danger(data.message)
                }
            } catch (error) {
                this.handleResponseErrors(error)
            } finally {
                this.loading = false
            }
        }
    },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>